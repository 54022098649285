<template>
  <div>
    <v-navigation-drawer
      app
      permanent
      v-model="drawer"
      :mini-variant="drawer"
    >
      <!-- -->

      <v-list nav dense>
        <v-list-item
          @click="drawer = !drawer"
          avatar
          class="v-list-item d-flex justify-end"
        >
          <v-list-item-title v-if="!drawer" v-text="'DIGITHIS'" />
          <v-icon v-if="drawer">mdi-text</v-icon>
          <v-icon v-else>mdi-window-close</v-icon>
        </v-list-item>
        <div v-for="(item, i) in items" :key="i">

          <v-list-item
            v-if="!item.children"
            :to="item.to"
            avatar
            class="v-list-item"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="item.text" />
          </v-list-item>

          <v-list-group
            v-else
            :key="item.text"
            no-action
            :prepend-icon="item.icon"
            :value="false"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="child in item.children"
              :to="child.to"
              :key="child.text"
            >
              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ child.text }}</v-list-item-title>

            </v-list-item>

          </v-list-group>

        </div>

      </v-list>

    </v-navigation-drawer>
  </div>
</template>

<script>
    export default {
        name: "Main",
        data() {
            return {
                drawer: true,
                color: 'warning',
                items: [
                    {
                        text: 'Dashboard',
                        icon: 'mdi-view-dashboard',
                        to: {
                            name: 'Dashboard'
                        }
                    },
                    {
                        text: 'Products',
                        icon: 'mdi-dropbox',
                        to: {
                            name: 'Products'
                        }
                    },
                    {
                        text: 'Customers',
                        icon: 'mdi-account-multiple',
                        to: {
                            name: 'Customers'
                        }
                    },
                    {
                        text: 'Authors',
                        icon: 'mdi-account-key',
                        to: {
                            name: 'Authors'
                        }
                    },
                    {
                        text: 'FTP users',
                        icon: 'mdi-account-switch-outline',
                        to: {
                            name: 'FTPUsers'
                        }
                    },
                    {
                        text: 'Publishers',
                        icon: 'mdi-book-edit',
                        to: {
                            name: 'Publishers'
                        }
                    },
                    {
                        text: 'Tags',
                        icon: 'mdi-book-edit',
                        to: {
                            name: 'Tags'
                        }
                    },
                    {
                      text: 'Banners',
                      icon: 'mdi-book-edit',
                      to: {
                        name: 'Banners'
                      }
                    },
                    {
                        text: 'Orders',
                        icon: 'mdi-calendar-week',
                        to: {
                            name: 'Orders'
                        }
                    },
                    {
                        text: 'Pages',
                        icon: 'mdi-calendar-week',
                        to: {
                            name: 'Pages'
                        }
                    },
                    {
                        text: 'To site',
                        icon: 'mdi-home',
                        to: {
                            name: 'Home'
                        }
                    },
                ],
            }
        }
    }
</script>

<style scoped>

</style>
