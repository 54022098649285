<template>
  <div>
    <v-app v-show="!preloader">
      <component :is="layout">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </component>
    </v-app>
    <global-loader v-show="preloader" />
  </div>
</template>
 
<script>
import HomeLayout from "./layouts/HomeLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import AdminLayout from "./layouts/AdminLayout";
import GlobalLoader from "./components/loaders/GlobalLoader";
import { mapActions, mapState } from "vuex";

export default {
  /*+++ debug data and methods*/
  /*todo remove after layout complete*/
  data() {
    return {
      // debugMode: !false,
      debugOpacity: 0.0
    };
  },
  async mounted() {
    await this.getBanners();
  },
  methods: {
    ...mapActions({
      getBanners: "banners/getBanners"
    }),
    changeOpacity(to) {
      let change = to === "+" ? +0.1 : -0.1;
      this.debugOpacity = this.debugOpacity + change;
      let root = document.documentElement;
      root.style.setProperty("--debug-opacity", this.debugOpacity);
      root.style.setProperty("--debug-content", this.debugMode ? "" : "unset");
    }
  },
  /*--- debug data and methods*/
  computed: {
    ...mapState({
      preloader: state => state.appInterface.preloader
    }),
    debugMode() {
      return process.env.NODE_ENV === "development";
    },
    layout() {
      return `${this.$route.meta.layout || "empty"}-layout`;
    }
  },

  components: { HomeLayout, EmptyLayout, GlobalLoader, AdminLayout }
};
</script>

<style lang="scss">
@import "assets/scss/components/_global.scss";
@import "assets/scss/components/slider/_product-slider.scss";

/*debugging styles*/
:root {
  --debug-opacity: 0;
  --debug-image: url("assets/pixelPerf/checkout-delivery.jpg");
  --debug-content: "";
}

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //color: #2c3e50;
  &:before {
    /*dev style for pixelPerfect*/
    content: var(--debug-content);
    content: unset;
    background: var(--debug-image) no-repeat top;
    background-size: 1600px;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 3000px;
    opacity: var(--debug-opacity);
    pointer-events: none;
  }
}
/* -- debugging styles*/
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
