<template>
    <div class="global-loader">
        <moon-loader :loading="preloader" color="var(--v-primary-base)"/>
    </div>
</template>

<script>
  import { MoonLoader } from '@saeris/vue-spinners'
  import {mapState} from 'vuex';

  export default {
    components: {
      MoonLoader
    },
    computed: {
      ...mapState({
        'preloader': state => state.appInterface.preloader
      }),
    }
    // ...
  }
</script>

<style lang="scss" scoped>
    @import "./src/assets/scss/components/loaders/global-loader";

</style>