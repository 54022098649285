<template>
  <state-indicate v-slot="{usePromise, isLoading}">
    <v-form class="mt-n10" v-model="valid">
      <v-container>
        <v-row no-gutters>
          <v-alert
            v-if="error"
            outlined
            type="error"
            border="left"
          >
            <span class="text-caption" v-html="error"></span>
          </v-alert>
          <v-col cols="12">
            <v-select
              label="Select your role"
              :items="roles"
              v-model="user.role"
              dense
              outlined
            >
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              label="E-mail"
              required
              dense
              outlined
              placeholder="E-mail"
              height="60"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="user.password"
              label="Password"
              type="password"
              :rules="passwordRules"
              required
              dense
              outlined
              placeholder="Password"
              height="60"
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-if="user.role === 'Customer'">
            <v-text-field
              v-model="user.first_name"
              :rules="nameRules"
              :counter="10"
              label="First name"
              required
              dense
              outlined
              placeholder="first name"
              height="60"
            ></v-text-field>
          </v-col>

          <v-col cols="12" v-if="user.role === 'Customer'">
            <v-text-field
              v-model="user.surname"
              :rules="nameRules"
              :counter="10"
              label="Last name"
              required
              dense
              outlined
              placeholder="surname"
              height="60"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          :loading="isLoading"
          type="submit"
          @click.prevent="usePromise(onSubmit())"
          color="primary"
          width="100%"
          x-large
          class="mt-3 rounded-lg"
        >Send
        </v-btn>
      </v-container>
    </v-form>
  </state-indicate>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'Registration',
  data: () => ({
    error: null,
    valid: false,
    user: {
      first_name: '',
      surname: '',
      email: '',
      password: '',
      role: 'Author',
    },

    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 10 || 'Name must be less than 10 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => v.length > 7 || 'Password length must be more than 7 characters',
    ],
    roles: ['Customer', 'Author']
  }),

  methods: {
    ...mapActions('auth', ['registration','dialog']),
    async onSubmit() {
      this.error = null;
      const res = await this.registration({...this.user}).then(ApiResponse => {
        this.dialog(false)
        this.$router.push('/')
      }).catch(ApiResponse => {
        if (ApiResponse.hasValidationErrors()) {
          const validationErrors = ApiResponse.getValidationErrors();

          this.error = '<div>You have errors in the following fields:<br></div>'
          this.error += '<ul>';

          Object.keys(validationErrors).forEach((key) => {
              this.error += `<li>${key}: ${validationErrors[key][0]}</li>`;
          });
          this.error += '</ul>';

        } else {
          this.error = 'An error occurred during registration';
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
