<template>
  <div>
    <template v-if="$route.name === 'order.checkout'">
      <HeaderCheckout />
    </template>
    <template v-else>
      <Header />
    </template>
    <slot></slot>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import HeaderCheckout from '@/components/HeaderCheckout'
import Footer from '@/components/Footer'

export default {
  name: "HomeLayout.vue",
  components: { Footer, Header, HeaderCheckout }
}
</script>
