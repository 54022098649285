import Product from "@/models/Product";

export const products = {
  namespaced: true,
  state: {
    list: [],
    meta: {},
    loading: false,
  },
  mutations: {
    SET_PRODUCTS(state, payload) {
      state.list = payload
    },
    SET_META(state, payload) {
      state.meta = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    }
  },
  actions: {
    async fetchProducts({commit}, payload) {
      commit('appInterface/SHOW_PRELOADER', null, { root: true })
      commit('SET_LOADING', true)
      try {
        const { filter, page } = payload;
        const response = await Product.params({'search': filter}).page(page).get()
        commit('SET_PRODUCTS', response.getData());
        commit('SET_META', response.getMeta());
      } catch (e) {
        //
      } finally {
        commit('appInterface/HIDE_PRELOADER', null, { root: true })
        commit('SET_LOADING', false)
      }
    }
  }
}
