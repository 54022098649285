import axios from 'axios';
import ApiResponse from "../api/ApiResponse";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(function (response) {
  return new ApiResponse(response);
}, function (error) {

    if (error.status === 422) {
        return new ApiResponse(error.response)
    }

    return Promise.reject(new ApiResponse(error.response));
});

export default axios
