import {store} from '@/store';

export default async function isAdmin ({ next  }) {
  const {
    getters: {
      'auth/isAdmin': admin
    }
  } = store;
  return admin ? next() : next({name: 'Home'})
}
