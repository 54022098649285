import Api from "../api";
import router from "../router";

export const auth = {
  namespaced: true,

  state: () => ({
    authenticated: false,
    user: null,
    dialogSign: false
  }),

  getters: {
    isDialogOpened(state) {
      return !state.dialogSign ? null : state.dialogSign
    },
    isAdmin(state) {
      return !state.user ? null : state.user.role === 'admin'
    },
    isAuthor(state) {
      return !state.user ? null : state.user.role === 'author'
    },
    isCustomer(state) {
      return !state.user ? null : state.user.role === 'customer'
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },

    SET_USER(state, value) {
      state.user = value
    },

    SET_DIALOG(state, value) {
      state.dialogSign = value
    }
  },
  actions: {
    dialog({commit}, value) {
      commit('SET_DIALOG', value)
    },
    login({dispatch}, credentials) {
      return new Promise(async (resolve, reject) => {
        await Api.sanctumCsrfCookie();

        Api.login(credentials).then(async () => {
          await dispatch('getProfileData');
          await dispatch('cart/fetchCart', null, { root: true });
          resolve();
        }).catch((ApiResponse) => reject(ApiResponse));
      });
    },

    async registration({dispatch}, credentials) {
      return new Promise(async (resolve, reject) => {
        await Api.registration(credentials).catch((ApiResponse) => reject(ApiResponse))

        Api.login(credentials).then(async () => {
          await dispatch('getProfileData');
          resolve();
        }).catch((ApiResponse) => reject(ApiResponse));
      });
    },
    //Forget pass, send mail
    async forgot({dispatch}, credentials) {
      return new Promise(async (resolve, reject) => {
        await Api.forgot(credentials).catch((ApiResponse) => reject(ApiResponse))
        resolve();

      });
    },
    //Reset pass
    async resetPassword({dispatch}, credentials) {
      return new Promise(async (resolve, reject) => {
        await Api.resetPassword(credentials).catch((ApiResponse) => reject(ApiResponse))
      });
    },
    async updatePassword({dispatch}, credentials) {
      return new Promise(async (resolve, reject) => {
        await Api.updatePassword(credentials).catch((ApiResponse) => reject(ApiResponse))
        Api.login(credentials).then(async () => {
          await dispatch('getProfileData');
          resolve();
        }).catch((ApiResponse) => reject(ApiResponse));
      });
    },
    async logout({commit}) {
      commit('appInterface/SHOW_PRELOADER', null, {root: true});
      await Api.logout();
      commit('appInterface/HIDE_PRELOADER', null, {root: true});
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      try {
        router.push({'name': 'Home'})
      } catch {
      }
    },

    getProfileData({commit}) {
      return Api.getProfile().then((ApiResponse) => {
        try {
          console.log(ApiResponse)
          console.log(ApiResponse.getData())
        } catch (e) {
          console.log(e)
        }
        commit('SET_AUTHENTICATED', true);
        commit('SET_USER', ApiResponse.getData())
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }
  },
};
