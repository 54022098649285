import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store';
import middlewarePipeline from "./middleware/middlewarePipeline";
import guest from "./middleware/guest";
import auth from "./middleware/auth";
import isAdmin from "./middleware/isAdmin";
import hasCartItems from "./middleware/hasCartItems";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ('../views/Home.vue'),
        meta: { layout: 'home', breadCrumb: 'Home' },
    },
    {
        path: '/instruction',
        name: 'Instruction page',
        component: () =>
            import ('../views/Instruction.vue'),
        meta: { layout: 'home', breadCrumb: 'Instruction page' },
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () =>
            import ('../views/Terms.vue'),
        meta: { layout: 'home', breadCrumb: 'Terms and Conditions' },
    },
    {
        path: '/about',
        name: 'About us',
        component: () =>
            import ('../views/About.vue'),
        meta: { layout: 'home', breadCrumb: 'About us' },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () =>
            import ('../views/Contact.vue'),
        meta: { layout: 'home', breadCrumb: 'Contact' },
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () =>
            import ('../views/Privacy.vue'),
        meta: { layout: 'home', breadCrumb: 'Privacy Policy' },
    },
    {
        path: '/returns',
        name: 'Returns',
        component: () =>
            import ('../views/Returns.vue'),
        meta: { layout: 'home', breadCrumb: 'Returns Policy' },
    },
    {
        path: '/search',
        name: 'search',
        component: () =>
            import ('../views/Search.vue'),
        meta: { layout: 'home', breadCrumb: 'Search' },
    },
    {
        path: '/search/:search',
        name: 'search.query',
        component: () =>
            import ('../views/Search.vue'),
        meta: { layout: 'home', breadCrumb: 'Search' },
    },
    {
        path: '/books',
        name: 'books',
        component: () =>
            import ('../views/Products.vue'),
        meta: { layout: 'home', breadCrumb: 'Products' },
    },
    {
        path: '/books/:slug',
        name: 'books.show',
        component: () =>
            import ('../views/Product.vue'),
        meta: { layout: 'home', breadCrumb: 'Products' },
    },
    {
        path: '/cart',
        name: 'cart',
        component: () =>
            import ('../views/Cart.vue'),
        meta: { layout: 'home', middleware: [auth], breadCrumb: 'Cart' },
    },
    {
        path: '/order/checkout',
        name: 'order.checkout',
        component: () =>
            import ('../views/order/CheckoutWrapper.vue'),
        meta: { layout: 'home', middleware: [auth] },
        breadCrumb: 'Checkout',
    },
    {
        path: '/order/success',
        name: 'order.success',
        component: () =>
            import ('../views/order/Success.vue'),
        meta: { layout: 'home', breadCrumb: 'Success' },
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/auth/SigninSignupWrapper.vue'),
        // component: () => import('../views/auth/Login.vue'),
        meta: { middleware: [guest], breadCrumb: 'Login' },
    },
    {
        path: '/registration',
        name: 'Registration',
        component: () =>
            import ('../views/auth/Registration.vue'),
        meta: { middleware: [guest], breadCrumb: 'Registration' },
    },
    {
        path: '/forgot-passsword',
        name: 'Forgot',
        component: () =>
            import ('../views/auth/Forgot.vue'),
        meta: { middleware: [guest], breadCrumb: 'Forgot' },
    },
    {
        path: '/reset-password/:token',
        name: 'Reset-pass',
        component: () =>
            import ('../views/resetPassword/resetPassword'),
        meta: {  middleware: [guest]},
        props: true,
    },
    {
        path: '/profile',
        name: 'UserProfile',
        component: () =>
            import ('../views/UserProfile.vue'),
        meta: { layout: 'home', middleware: [auth], breadCrumb: 'User Profile' },
        redirect: {
            name: 'MyAccount'
        },
        children: [{
                path: 'my-account',
                name: 'MyAccount',
                component: () =>
                    import ('../views/profile/MyAccount.vue'),
                meta: { layout: 'home', middleware: [auth], breadCrumb: 'My Account' },
            },
            {
                path: 'my-wishlist',
                name: 'MyWishlist',
                component: () =>
                    import ('../views/profile/MyWishlist.vue'),
                meta: { layout: 'home', middleware: [auth], breadCrumb: 'My Wish list' },
            },
            {
                path: 'my-orders-history',
                name: 'MyOrdersHistory',
                component: () =>
                    import ('../views/profile/MyOrdersHistory.vue'),
                meta: { layout: 'home', middleware: [auth], breadCrumb: 'My Orders History' },
            },
        ]
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () =>
            import ('../views/admin/Main.vue'),
        redirect: {
            'name': 'Dashboard'
        },
        meta: { layout: 'admin', middleware: [isAdmin] },
        children: [
          {
                path: 'ftp-users',
                name: 'FTPUsers',
                component: () => import('../views/admin/ftpUsers/Crud.vue'),
                meta:{ layout: 'admin', middleware: [isAdmin] },
          },
          {
                path: 'dashboard',
                name: 'Dashboard',
                component: () =>
                    import ('../views/admin/dashboard/Dashboard.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'products',
                name: 'Products',
                component: () =>
                    import ('../views/admin/products/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'authors',
                name: 'Authors',
                component: () =>
                    import ('../views/admin/authors/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'customers',
                name: 'Customers',
                component: () =>
                    import ('../views/admin/customers/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'publishers',
                name: 'Publishers',
                component: () =>
                    import ('../views/admin/publishers/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'banners',
                name: 'Banners',
                component: () =>
                    import ('../views/admin/banners/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'orders',
                name: 'Orders',
                component: () =>
                    import ('../views/admin/orders/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'pages',
                name: 'Pages',
                component: () =>
                    import ('../views/admin/pages/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            },
            {
                path: 'tags',
                name: 'Tags',
                component: () =>
                    import ('../views/admin/tags/Crud.vue'),
                meta: { layout: 'admin', middleware: [isAdmin] },
            }
        ]
    },
    {
        path: '/404',
        name: 'NotFoundPage',
        component: () =>
            import ('../views/NotFoundPage.vue'),
    }, {
        path: '*',
        redirect: '/404'
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

let init = false;

router.beforeEach(async(to, from, next) => {
    if (init === false) {
        store.commit('appInterface/SHOW_PRELOADER');

        await store.dispatch('auth/getProfileData', null, { root: true });

        store.commit('appInterface/HIDE_PRELOADER');
        await store.dispatch('cart/fetchCart')

        //await store.dispatch('getGeolocation', null, {root: true});

        init = true;
    }

    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = { to, from, next, store }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})
export default router
