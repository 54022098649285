export const search = {
  namespaced: true,

  state: () => ({
    query: ''
  }),

  mutations: {
    SET_QUERY(state, value) {
      state.query = value
    },
  },

  actions: {
    setQuery({commit}, value) {
      commit('SET_QUERY', value)
    }
  }
};
