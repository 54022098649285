import axios from "axios";

export default {
  sanctumCsrfCookie() {
    return axios.get('/sanctum/csrf-cookie')
  },
  login(credentials) {
    return axios.post('/api/login', credentials)
  },
  forgot(email) {
    return axios.post('/api/forgot-password', email)
  },
  resetPassword(token) {
    return axios.get('/api/reset-password', token)
  },
  updatePassword(email, token, password) {
    return axios.post('/api/update-password', email, token, password)
  },
  logout(credentials) {
    return axios.post('/api/logout');
  },
  getProfile() {
    return axios.get('/api/profile')
  },
  registration(credentials) {
    let url = credentials.role === 'Customer' ? 'customer' : 'author'
    return axios.post(`/api/register/${url}`, { ...credentials })
  },
  async addToCart(params) {
      return axios.post(`/api/carts/add`, params)
  },
  fetchCart() {
        return axios.get(`/api/carts/list`)
  },
  placeOrder() {
      return axios.post('/api/orders')
  },
  syncFavorites(favorites) {
      return axios.post('/api/favorites/sync', {
        favorites
      })
  },
  fetchFavorites() {
    return axios.get('/api/favorites/list')
  },
  getBanners() {
    return axios.get('/api/banners?active=1')
  },
  attachCartItem(product) {
    return axios.post('/api/carts/attach', {
      'product_id': product.id
    })
  },
  detachCartItem(product) {
    return axios.post('/api/carts/detach', {
      'product_id': product.id
    })
  }
}
