import {isObject} from "vuetify/lib/util/helpers";

const getWrappedDataOrSimple = function() {
  return this.wrappedResponse && this.data.hasOwnProperty('data') ? this.data.data : this.data
};

const getWrappedMetaOrSimple = function() {
  return this.data.hasOwnProperty('meta') ? this.data.meta : null
};

export default class {
  constructor(response) {
    let validResponse = isObject(response);

    validResponse && ['data', 'status', 'statusText', 'headers', 'config'].forEach((property) => {
      if (!response.hasOwnProperty(property)) {
        validResponse = false;
        return false;
      }
    });

    if (!validResponse) {
      throw 'Expected AxiosResponse';
    }

    this.originalResponse = response;
    this.data = this.originalResponse.data;
    this.status = this.originalResponse.status;
    const contentable = this.status === 204 || this.status === 201;
    this.wrappedResponse = this.data && this.data.hasOwnProperty('_reference');

    if (!this.wrappedResponse && !contentable) {
      throw 'Expected Valid digithis response';
    }
  }

  successfully() {
    return this.wrappedResponse
      ? !!this.data.success
      : this.status >= 200 && this.status < 300 && this.hasValidJsonResponse();
  }

  getValidationErrors() {
    return this.hasValidationErrors() ? this.data.errors : [];
  }

  hasValidationErrors() {
    return this.wrappedResponse && this.data.hasOwnProperty('errors'); // todo change later
  }

  hasValidJsonResponse() {
    const data = getWrappedDataOrSimple.bind(this)();

    return Array.isArray(data) || isObject(data) || ( data && /^[\],:{}\s]*$/.test(data.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, '')));
  }

  getMeta() {
    if (!this.hasValidJsonResponse()) {
      throw 'Not valid json response';
    }

    const meta = getWrappedMetaOrSimple.bind(this)();

    if (meta) {
      return meta;
    } else {
      throw 'Not valid meta json response';
    }
  }

  getData() {
    if (!this.hasValidJsonResponse()) {
      throw 'Not valid json response';
    }

    const data = getWrappedDataOrSimple.bind(this)();

    if (this.wrappedResponse) {
      if (this.data.hasOwnProperty('data')) {
        return data
      } else {
        throw 'Not found wrappedData property'
      }
    } else {
      return data;
    }
  }
}
