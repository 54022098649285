<template>
    <section>
        <v-app id="inspire">
            <navigation></navigation>

            <v-main>
                <transition
                    name="fade"
                >
                    <router-view/>
                </transition>
            </v-main>
        </v-app>
    </section>
</template>

<script>
    import Navigation from "../views/admin/partials/Navigation";

    export default {
        name: "AdminLayout",
        components: {
            Navigation
        }
    }
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>
