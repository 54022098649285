import ApiTags from '../api/tags'

export const tags = {
  namespaced: true,

  state: () => ({
    tags: [],
  }),
  mutations: {
    SET_TAGS(state, value) {
      state.tags = value
    }
  },
  actions: {
    async fetchTags({commit}) {
      return new Promise(async (resolve, reject) => {
        await ApiTags.list()
          .then(async ApiResponse => {
            let tags = ApiResponse.getData()
            //TODO icon images must be getting from BD (tags table)
            let images = [
              require('@/assets/images/tags/academic-01.svg'),
              require('@/assets/images/tags/architecture-01.svg'),
              require('@/assets/images/tags/art-01.svg'),
              require('@/assets/images/tags/child-01.svg'),
              require('@/assets/images/tags/craft-01.svg'),
              require('@/assets/images/tags/fiction-01.svg')
            ]
            let noIcon = require('@/assets/images/logomark-beige-01.svg')
            tags.map((v, i) => {
              v.image = images[i] || noIcon
            })
            commit('SET_TAGS', tags)
            resolve(tags);
          })
      })
    }
  }
}
