<template>
    <span>
        <slot v-bind:leastOneIsLoading="leastOneIsLoading"></slot>
    </span>
</template>

<script>
  import isArray from 'lodash/fp/isArray';

  export default {
    name: "StateIndicateObserver",
    data() {
      return {
        stateIndicateChildren: []
      }
    },
    computed: {
      leastOneIsLoading() {
        return !!this.stateIndicateChildren.length && !!this.stateIndicateChildren.find(c => c.isLoading === true);
      }
    },
    methods: {
      findStateIndicateChildrenComponents(arrayOfVNode, deep = 0) {
        if (!isArray(arrayOfVNode)) {
          throw new Error('Bad argument was passed to `findStateIndicateChildrenComponents` method')
        }
        
        if (deep >= 10) {
          return null;
        }

        arrayOfVNode.forEach((VueComponent) => {
          if (VueComponent instanceof Object && VueComponent.hasOwnProperty('$options') && VueComponent.$options instanceof Object) {
            if (VueComponent.$options._componentTag === 'state-indicate') {
              this.stateIndicateChildren.push(VueComponent);
            } else if(VueComponent.$children.length){
              this.findStateIndicateChildrenComponents(VueComponent.$children, deep + 1)
            }
          }
        })
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.findStateIndicateChildrenComponents(this.$children);
      })
    }
  }
</script>

<style scoped>

</style>