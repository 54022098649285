<template>
  <div>
    <template v-if="forgott == true"><forgot></forgot></template>
    <div class="text-center mt-n5" v-show="!forgott">
    <section class="auth">
      <state-indicate v-slot="{usePromise, isLoading}">
        <v-form v-model="valid" class="px-4" @submit.prevent="usePromise(submit())">
          <v-alert
            v-if="error"
            outlined
            type="error"
            border="left"
          >
            <span class="text-caption">{{ error }}</span>
          </v-alert>
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                class="mr-2 search__input rounded-lg "
                dense
                outlined
                placeholder="email"
                height="60"
            ></v-text-field>
            <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                type="password"
                required
                class="mr-2 search__input rounded-lg "
                dense
                outlined
                placeholder="Password"
                height="60"
            ></v-text-field>
          <v-row
            justify="space-between"
            align="center"
            class="text-left px-5 mt-n7 staySigned" >
            <v-checkbox
              :input-value="staySigned"
              label="Stay signed in"
            ></v-checkbox>
            <a  @click.prevent="forgott = true" class="btn btn-link" style="color: #a5a7aa;">Forgot your password?</a>
            <!-- <router-link to="#" @click.prevent="forgott = true" style="color: #a5a7aa;">Forgot your password?</router-link> -->
          </v-row>

           <v-btn
             :loading="isLoading"
             x-large
             type="submit"
             width="100%"
             color="primary"
             class="mt-3 rounded-lg"
           >Sign In
           </v-btn>
        </v-form>
      </state-indicate>
<!--      <v-row class="mt-3">
        <v-col>
          <p style="color: #a5a7aa;">OR</p>
          <router-link to="#">
            <img src="@/assets/images/google_signin.png"></img>
          </router-link>
          <router-link to="#">
            <img src="@/assets/images/facebook_signin.png"></img>
          </router-link>
        </v-col>
      </v-row>-->
    </section>
  </div>
  </div>
  
</template>

<script>
// import Header from '@/components/Header'
import { mapActions } from 'vuex'
import Forgot from "@/views/auth/Forgot";
export default {
  name: 'Login',
  // comments:{Header},
  components:{Forgot},
  data: () => ({
    valid: false,
    error: null,
    forgott: false,
    items:['SIGN IN', 'SIGN UP'],
    tab:null,
    staySigned:false,
    email: '',
    password: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
    ],
  }),
  methods: {
    ...mapActions('auth', ['login','dialog']),
    async submit() {
      this.error = null;
      return this.login({ email: this.email, password: this.password })
        .then((ApiResponse) => {
          this.dialog(false)
          this.$router.push('/')
      }).catch((ApiResponse) => {
        this.error = 'Your username and password combination is invalid, please re-enter.';
      })
    }
  }
}
</script>

<style lang="scss" scoped>

    .auth {
        //height: 100vh;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        //align-items: center;
        //background-color: white;
    }

    //form {
    //    display: flex;
    //    flex-direction: column;
    //    align-items: center;
    //    //width: 40%;
    //    .v-text-field {
    //        width: 100%;
    //    }
    //}
</style>
