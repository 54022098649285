<template>
  <v-container class="pa-4 pa-md-0" style="border-bottom: 1px solid var(--color-gray);">
    <v-row no-gutters class="justify-space-between">
      <v-col md="3" cols="12" class="d-flex align-center">
        <v-btn
          :to="{name: 'Home'}"
          color="transparent"
          text
          rounded
          class="my-4 pa-0 pr-5"
          exact-active-class=""
        >
          <v-img width="130px" src="../assets/images/logo-01.svg"></v-img>
        </v-btn>
        <div class="d-flex align-baseline">
          <v-icon color="var(--color-gray)">mdi-lock-outline</v-icon>
          <div style="color: var(--color-gray);margin-left: 3px;">Secure checkout</div>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <checkout-steeper-header />

<!--      <v-col cols="9">-->
<!--      </v-col>-->
    </v-row>

  </v-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
// import ApiTags from "@/api/tags";
import CheckoutSteeperHeader from '@/components/utils/CheckoutSteeperHeader'

export default {
  name: "Header",
  components: {CheckoutSteeperHeader},
  data() {
    return {
      //
    }
  },
  async mounted() {
    // await ApiTags.index().then(ApiResponse => {
    //   this.tags = ApiResponse.getData()
    // })

  },
  computed: {
    ...mapState({
      'authenticated': state => state.auth.authenticated,
      'user': state => state.auth.user,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
    }),
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    closeDialog() {
      window.console.log('closeDialog');
      this.dialogSignIn = false
    }
    // logout() {
    //
    // }
  }
}
</script>

<style scoped lang="scss">
//@import '~vuetify/src/components/VStepper/_variables.scss';
.nav {
  display: flex;
  justify-content: flex-start;
}

.mobile__menu {
  list-style: none;
  padding: 0;

  li {
    width: 100%;
    background: #efe5e6;
    border: 2px solid #fff;
    border-radius: 1em;
    padding: 1em 2em;

    a {
      color: #000;
      text-decoration: none;
    }
  }
}

.menu-item {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: .85em;
  color: #000000;
  text-decoration: none;

  &:hover {
    text-shadow: 0 0 3px #efe5e6;
    text-decoration: underline;
  }
}

.auth {
  display: flex;
  justify-content: flex-end;
}
</style>
