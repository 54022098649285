<template>
  <v-container style="width: calc(120px*3)">
    <v-row justify="start" no-gutters>
      <v-col cols="4" class="pa-0 ma-0" v-for="(stage,idx) in stages" :key="idx">
        <div class="stage_wrapper">
          <div
            :class="[steep >= idx ? 'primary' : 'secondary lighten-5', 'stage_bar']"
            @click="setSteep(idx)">
          </div>
          <div :class="[steep >= idx ? 'stage t_primary' : 'stage']">
            {{ stage }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: "CheckoutSteeperHeader",
  data: () => ({
    stages: [
      // 'Delivery',
      'Payment',
      'Review',
    ]
  }),
  computed: {
    ...mapState({
      steep: state => {
        return state.cart.steep;
      }
    }),
  },
  mounted() {
    // this.fetchDelivery()
    this.fetchPayment()
  },
  methods: {
    ...mapActions({
      setSteep: 'cart/setSteep',
      fetchDelivery: 'cart/fetchDelivery',
      fetchPayment: 'cart/fetchPayment'
    }),
  }

}
</script>

<style scoped>
.stage_bar {
  width: 120px;
  height: 20px;
}

.stage {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-size: .9em;
  color: var(--color-gray);
}

.t_primary {
  color: var(--color-blue);
}
</style>
