<template>
<div class="white">
  <v-tabs
    v-model="tab"
    grow
  >
    <v-tabs-slider color="#0d346d"></v-tabs-slider>

    <v-tab
      v-for="item in items"
      :key="item"
      active-class="black--text"
    >
      {{ item }}
    </v-tab>
  </v-tabs>
  <v-container class="mt-0 mt-md-7">
    <v-row>
      <v-col>
        <template v-if="tab === 0"><login></login></template>
        <template v-else-if="tab === 1"><registration></registration></template>
        
      </v-col>
    </v-row>
  </v-container>


</div>
</template>

<script>
import Login from "@/views/auth/Login";
import Registration from "@/views/auth/Registration";

export default {
name: "SigninSignupWrapper",
  components:{Login,Registration},
  data: () => ({
    valid: false,
    items: ['SIGN IN', 'SIGN UP'],
    tab: null,
  })
}
</script>

<style scoped>

</style>
