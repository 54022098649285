<template>
  <section>
    <slot></slot>
  </section>
</template>

<script>

export default {
  name: "EmptyLayout.vue",
}
</script>
