import axios from "axios";

export default {
  index() {
    return axios.get('/api/tags')
  },
  update(id, data) {
    return axios.post(`/api/tags/${id}`, data)
  },
  store(data) {
    return axios.post(`/api/tags`, data)
  },
  delete(id) {
    return axios.delete(`/api/tags/${id}`)
  },
  list() {
    return axios.get('/api/tags/list')
  },
}
