import Api from '@/api';

export const banners = {
  namespaced: true,

  state: {
    items: {main:[], selections: []},
  },

  getters: {

  },

  mutations: {
    SET_BANNERS(state, payload) {
      state.items = payload
    },
  },
  actions: {
    async getBanners({commit}, params) {
      function groupBy(xs, f) {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
      }

      let payload = await Api.getBanners()
      let banners = payload.data.data

      banners = groupBy(banners, b => b.type)

      console.log(banners)
      commit('SET_BANNERS', banners)
    },
  }
};
