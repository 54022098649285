<template>
    <div class="text-center mt-n5">
    <section class="auth" v-if="sended">
      <span>The reset code was sent successfully. Please check your e-mail. </span>
    </section>
    <section class="auth" v-else>
      <state-indicate v-slot="{usePromise, isLoading}">
        <v-form v-model="valid" class="px-4" @submit.prevent="usePromise(submit())">
          <v-alert
            v-if="error"
            outlined
            type="error"
            border="left"
          >
            <span class="text-caption">{{ error }}</span>
          </v-alert>
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                class="mr-2 search__input rounded-lg "
                dense
                outlined
                placeholder="email"
                height="60"
            ></v-text-field>
           <v-btn
             :loading="isLoading"
             x-large
             type="submit"
             width="100%"
             color="primary"
             class="mt-3 rounded-lg"
           >Reset password
           </v-btn>
        </v-form>
      </state-indicate>

    </section>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Forgot',
  data: () => ({
    valid: false,
    error: null,
    sended: false,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    ...mapActions('auth', ['forgot','dialog']),
    async submit() {
      this.error = null;
      const res = await this.forgot({ email: this.email }).then(ApiResponse => {
        this.sended = true
        this.$router.push('/')
      }).catch(ApiResponse => {
        this.error = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>

    .auth {
        //height: 100vh;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        //align-items: center;
        //background-color: white;
    }

    //form {
    //    display: flex;
    //    flex-direction: column;
    //    align-items: center;
    //    //width: 40%;
    //    .v-text-field {
    //        width: 100%;
    //    }
    //}
</style>
