import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'
import vuetify from './plugins/vuetify';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import CKEditor from '@ckeditor/ckeditor5-vue2'

import StateIndicate from "./components/utils/StateIndicate";
import StateObserver from "./components/utils/StateIndicateObserver";

Vue.component('state-indicate', StateIndicate);
Vue.component('state-observer', StateObserver);

Vue.config.productionTip = false;

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// with typescript
for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  });
}

require('./bootstrap');
require('./sentry')

Vue.use( CKEditor );
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
