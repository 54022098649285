import Vue from 'vue'
import Vuex from 'vuex'
import {tags} from './tags'
import {auth} from './auth'
import {banners} from './banners'
import {cart} from './cart'
import {appInterface} from './appInterface'
import {products} from './products';
import {search} from './search'
//import {geolocation} from './geolocation';
import {favorites} from './favorites'
import {productReviews} from './product-reviews'

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {

  },
  modules: {
      tags, auth, cart, appInterface, products, favorites, productReviews, banners, search
  }
});
