import { Model as BaseModel } from 'vue-api-query'
import ApiResponse from "@/api/ApiResponse";

export default class Model extends BaseModel {

  // Define a base url for a REST API
  baseURL() {
    return process.env.VUE_APP_API_URL + '/api'
  }

  // Implement a default request method
  request(config) {
    return this.$http.request(config).then(res => res)
  }

  /**
   * @return ApiResponse
   */
  get() {
    let base = this._fromResource || `${this.baseURL()}/${this.resource()}`;
    base = this._customResource ? `${this.baseURL()}/${this._customResource}` : base;
    let url = `${base}${this._builder.query()}`;

    return this.request(
      this._reqConfig({
        url,
        method: 'GET'
      })
    ).then(response => {
      let collection = this._applyInstanceCollection(response.data)

      if (response.data.data !== undefined) {
        response.data.data = collection
      } else {
        response.data = collection
      }

      return response
    })
  }
}