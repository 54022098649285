import Api from '@/api';
import {isAuthenticated} from '@/helpers/auth';

export const favorites = {
  namespaced: true,

  state: {
    items: [],
  },

  getters: {

  },

  mutations: {
    SET_FAVORITES(state, payload) {
      state.items = payload
    },
  },
  actions: {
    async addToFavorites({commit}, params) {
      let items = this.state.favorites.items;
      items.push(params.product);

      if (isAuthenticated()) {
        await Api.syncFavorites(items.map(product => product.id))
      }

      await localStorage.setItem('favorites', JSON.stringify(items))
      commit('SET_FAVORITES', items)
    },

    async removeFromFavorites({commit}, params) {
      let items = this.state.favorites.items;
      items = items.filter(product => product.id !== params.product.id)

      if (isAuthenticated()) {
        await Api.syncFavorites(items.map(product => product.id))
      }

      await localStorage.setItem('favorites', JSON.stringify(items))
      commit('SET_FAVORITES', items)
    },

    async fetchFavorites({commit}, params) {
      let items = JSON.parse(localStorage.getItem('favorites')) || []

      if (isAuthenticated()) {
        items = []
        let productsInFavorites = await Api.fetchFavorites()
        productsInFavorites.getData().forEach(product => items.push(product))
      }

      await localStorage.setItem('favorites', JSON.stringify(items))
      commit('SET_FAVORITES', items)
    }
  },
};
