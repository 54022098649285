<template>
  <v-row class="justify-space-around">
    <v-col cols="12">
      <div class="d-flex  mt-3">
        <v-text-field
          @keydown.enter="handleSearch"
          @keydown.esc="searchQuery = ''"
          class="mr-2 search__input rounded-lg "
          background-color="#efe5e6"
          dense
          outlined
          placeholder="Search for books"
          height="49"
          v-model="searchQuery"
          @input="$emit('input', $event)"
        >
          <template slot="append">
            <v-icon @click="searchQuery = ''"
              color="black" size="25" class="px-4"
            >mdi-close-circle-outline</v-icon>
            <v-icon @click="handleSearch"
                color="black" size="30" class="mt-1"
            >mdi-magnify</v-icon>
          </template>
        </v-text-field>
<!--        <v-btn @click="handleSearch">
          <v-icon>mdi-search</v-icon>
          Search
        </v-btn>-->
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Search',
  props: {
    value: {
      type: String,
      default: null,
    }
  },
  data() {
      return {
          searchQuery: this.value || ''
      }
  },
  methods: {
    async handleSearch() {
      this.$router.push({ name: 'search' })
      await this.$store.dispatch('search/setQuery', this.searchQuery, {root: true});
    },
    handleInput(event) {
      console.log(event);
    }
  }
}
</script>

<style lang="scss">
  @import "./src/assets/scss/components/search";
</style>
