import axios from "axios";

export default {
  store(product,data) {
    return axios.post(`/api/products/${product.slug}/reviews`, data)
  },
  index(product) {
    return axios.get(`/api/products/${product.slug}/reviews`)
  }
}
