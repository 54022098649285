import Api from "../api";
import {isAuthenticated} from "../helpers/auth";

export const cart = {
  namespaced: true,

  state: {
    cart: {},
    items: [],
    delivery: {},
    payment: {},
    steep: 0,
  },

  getters: {},

  mutations: {
    SET_CART(state, cart) {
      state.cart = cart
    },
    SET_CART_ITEMS(state, products) {
      state.items = products
    },
    SET_CART_DELIVERY(state, delivery) {
      state.delivery = delivery
    },
    SET_CART_PAYMENT(state, payment) {
      state.payment = payment
    },
    SET_STEEP(state, steep) {
      state.steep = steep
    },
  },
  actions: {
    setSteep({commit}, params) {
      commit('SET_STEEP', params)
    },

    async setDelivery({commit}, params) {
      await localStorage.setItem('delivery', JSON.stringify(params))
      commit('SET_CART_DELIVERY', params)
    },
    async fetchDelivery({commit}, params) {
      let delivery = await JSON.parse(localStorage.getItem('delivery')) || params || []
      commit('SET_CART_DELIVERY', delivery)
    },
    async setPayment({commit}, params) {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          localStorage.setItem('payment', JSON.stringify(params))
          commit('SET_CART_PAYMENT', params)
          resolve()
        }, 1000) // @todo save card on backed?
      })

    },
    async fetchPayment({commit}, params) {
      let payment = await JSON.parse(localStorage.getItem('payment')) || params || []
      commit('SET_CART_PAYMENT', payment)
    },
    async addToCart({commit}, params) {
      if (!params.product || !params.product.id) return;

      let products = this.state.cart.items;

      if (isAuthenticated()) {
        await Api.attachCartItem(params.product)
      }

      products.push(params.product);

      await localStorage.setItem('cartItems', JSON.stringify(products))
      commit('SET_CART_ITEMS', products)
    },

    async removeFromCart({commit}, params) {
      let products = this.state.cart.items;
      products = products.filter(product => product.id !== params.product.id)

      if (isAuthenticated()) {
        await Api.detachCartItem(params.product)
      }

      await localStorage.setItem('cartItems', JSON.stringify(products))
      commit('SET_CART_ITEMS', products)
    },

    async fetchCart({commit}, params) {
      let products = JSON.parse(localStorage.getItem('cartItems')) || []

      if (isAuthenticated()) {
        products = []
        Api.fetchCart().then(async (ApiResponse) => {
          ApiResponse.getData().forEach(product => products.push(product))
          await localStorage.setItem('cartItems', JSON.stringify(products))
          commit('SET_CART_ITEMS', products)
        }).catch(async () => {})
      } else {
        await localStorage.setItem('cartItems', JSON.stringify(products))
        commit('SET_CART_ITEMS', products)
      }
    },

    async truncate({commit}, params) {
      commit('SET_CART_ITEMS', [])
    }
  },
};
