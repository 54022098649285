<template>
  <v-footer dark min-height="200">
    <v-container class="mt-8">
      <!--      <v-row>
              <v-col md="12" class="footer-column pb-7">
                <pre>
                  {{ tags }}
                </pre>
              </v-col>
      </v-row>-->
      <v-row>
        <v-col md="3" class="footer-column pb-7">
          <v-img width="196px" src="../assets/images/logo-wht-01.svg"></v-img>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" md="5">
          <v-container v-if="tags && tags.length > 0 ">
            <v-row>
              <v-col cols="12">
                <div class="footer__title d-flex justify-space-between">
                  <span>Products</span>
                  <v-icon
                    class="hidden-md-and-up"
                    @click="menuMobileShow.products = !menuMobileShow.products"
                  >{{ menuMobileShow.products ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </div>
              </v-col>
              <v-col cols="6" v-show="menuMobileShow.products || $vuetify.breakpoint.mdAndUp">
                <ul>
                  <li
                    class="my-3"
                    :key="idx"
                    v-for="(item,idx) in tags.slice(0,  Math.ceil(tags.length / 2))"
                  >
                    <router-link
                      :to="{name: 'books'}"
                    >{{ item.name ? item.name.replace(/\.$/, '') : '' }}</router-link>
                  </li>
                </ul>
              </v-col>
              <v-col cols="6" v-show="menuMobileShow.products || $vuetify.breakpoint.mdAndUp">
                <ul>
                  <li
                    class="my-3"
                    :key="idx"
                    v-for="(item,idx) in tags.slice(  Math.ceil(tags.length / 2), tags.length)"
                  >
                    <router-link
                      :to="{name: 'books'}"
                    >{{ item.name ? item.name.replace(/\.$/, '') : '' }}</router-link>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="7">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <div class="footer__title d-flex justify-space-between">
                  <span>General</span>
                  <v-icon
                    class="hidden-md-and-up"
                    @click="menuMobileShow.general = !menuMobileShow.general"
                  >{{ menuMobileShow.general ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </div>
                <ul v-show="menuMobileShow.general || $vuetify.breakpoint.mdAndUp">
                  <li class="my-3" :key="idx" v-for="(item,idx) in menu.general">
                    <router-link :to="{path:item.path}">{{ item.name }}</router-link>
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" md="6">
                <div class="footer__title d-flex justify-space-between">
                  <span>Legal</span>
                  <v-icon
                    class="hidden-md-and-up"
                    @click="menuMobileShow.legal = !menuMobileShow.legal"
                  >{{ menuMobileShow.legal ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </div>
                <ul v-show="menuMobileShow.legal || $vuetify.breakpoint.mdAndUp">
                  <li class="my-3" :key="idx" v-for="(item,idx) in menu.legal">
                    <router-link :to="{path:item.path}">{{ item.name }}</router-link>
                  </li>
                </ul>

                <v-card color="white" light class="pa-5 rounded-xl copyright">
                  <v-container>
                    <v-row>
                      <a target="_blank" href="https://www.facebook.com/booksdirectza" class="px-3">
                        <v-icon>mdi-facebook</v-icon>
                      </a>
                      <a target="_blank" href="https://twitter.com/booksdirectza" class="px-3">
                        <v-icon>mdi-twitter</v-icon>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/booksdirectza"
                        class="px-3"
                      >
                        <v-icon>mdi-instagram</v-icon>
                      </a>
                    </v-row>
                    <v-row class="pt-2">
                      <v-col>
                        Copyright &copy; 2021—21 DigiThis cc.
                        <br />All Rights Reserved.
                      </v-col>
                      <v-col>
                        made by
                        <a href="https://nomadicsoft.io">https://nomadicsoft.io</a>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <v-row>
        <div class="my-10"></div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapState({
      tags: state => state.tags.tags
    })
  },
  mounted() {
    // this.productTags()
  },
  methods: {
    // async productTags(){
    //    this.menu.products = await this.tags
    //   // window.console.log(this.menu.products);
    // }
  },
  data() {
    return {
      menuMobileShow: { products: false, general: false, legal: false },
      menu: {
        general: [
          { path: "/", name: "Home" },
          { path: "/about", name: "About" },
          { path: "profile", name: "My Account" },
          { path: "/instruction", name: "Instruction page" },
          { path: "/contact", name: "Contact" }
        ],
        legal: [
          { path: "terms", name: "Terms and Conditions" },
          { path: "/privacy", name: "Privacy Policy" },
          { path: "/returns", name: "Returns Policy" }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.v-application--wrap {
  //height: 2800px;
}

footer {
  //position: absolute;
  background: #0d346d;
  //bottom: 0;
  //margin-bottom: -320px;
  .v-image__image--cover {
    background-size: contain;
  }

  .footer__title {
    background-color: #1a3d70;
    padding: 0.5rem 2rem;
    border-radius: 1em;
    width: 100%;
  }

  .copyright {
    margin-top: 2em;
    color: #96888b;
    font-size: 0.7rem;

    a {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-left: 2.4em;
      font-size: smaller;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}

.theme--dark.v-footer {
  background-color: #0d346d;
}
</style>
