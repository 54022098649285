<template>
    <span>
        <slot v-bind:usePromise="usePromise" v-bind:isLoading="isLoading"></slot>
    </span>
</template>

<script>
  export default {
    name: "StateIndicate",
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      usePromise(promise) {
        this.isLoading = true;
        promise.finally(() => {
          this.isLoading = false
        })
      }
    }
  }
</script>

<style scoped>

</style>