<template>
  <v-menu
    v-model="menu"
	:close-on-content-click="false"
    :nudge-width="200"
    offset-x
	class="cart-menu-navigation"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :value="!!cartItems.length"
        color="green"
        class="text--white mt-6"
        :content="cartItems.length"
        overlap
      >
        <v-btn v-bind="attrs"
               v-on="on"
               color="transparent"
               elevation="0"
               text
               x-small
        >
          <v-img class="mt-1" width="12px" src="../assets/images/cart-01.svg"></v-img>
        </v-btn>
      </v-badge>
    </template>

    <v-card>


      <v-divider></v-divider>

      <v-list class="cart-menu_products-container" dense v-if="cartItems.length">
	   <v-list-item-group >
		<v-list-item class="cart-menu_item" v-for="(product, key) in cartItems.slice(0, showCount)" :key="key">
		 <v-list-item-avatar @click="toProduct(product)">
		  <img :src="product.image_url"/>
		 </v-list-item-avatar>

		 <v-row no-gutters>
		  <v-col class="pb-0 pt-0" cols="10" @click="toProduct(product)">
		   <v-list-item-title>{{product.name}}</v-list-item-title>
		  </v-col>
		  <v-col class="pb-0 pt-0" cols="10" @click="toProduct(product)">
		   <v-list-item-subtitle>Price: R {{product.price}}</v-list-item-subtitle>
		  </v-col>
		  <v-col class="mt-n4" cols="2">
		   <state-indicate v-slot:default="{usePromise, isLoading}">
			<v-btn
			  @click="usePromise(removeFromCart({product}))"
			  :loading="isLoading"
			  icon
			  color="error"
			>
			 <v-icon>mdi-close</v-icon>
			</v-btn>
		   </state-indicate>
		  </v-col>
		 </v-row>
		</v-list-item>
	   </v-list-item-group>
      </v-list>
      <v-card-text class="text-center" v-else>
        Your shopping cart is empty.
      </v-card-text>

      <v-divider></v-divider>
      <v-list v-if="cartItems.length > showCount">
        <v-list-item>
          <v-list-item-title class="text-center">And {{ moreDifferenceCount }} more positions</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-card-actions v-if="cartItems.length">

        <v-btn
          color="primary"
          :to="{name: 'order.checkout'}">
          Checkout
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :to="{name: 'cart'}"
        >
          Cart
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
  import {mapActions, mapState} from 'vuex';

    export default {
        name: "CartButton",
        computed: {
            ...mapState({
                cartItems: state => {
                    return state.cart.items;
                }
            }),

            moreDifferenceCount() {
                if (this.cartItems && this.cartItems.length > this.showCount) {
                    return this.cartItems.length - this.showCount;
                }
            }
        },
        data: function () {
            return {
                itemCountLocal: null,
                message: null,
                hints: false,
                menu: false,
                showCount: 5,
            }
        },
	  methods: {
        ...mapActions({
          removeFromCart: 'cart/removeFromCart'
        }),
		toProduct(product) {
          this.$router.push({name: 'books.show', params: {slug: product.slug}});
          this.menu = false;
		}
	  }
    }
</script>

<style lang="scss" scoped>
 @import "./src/assets/scss/components/cart/cart-menu-navigation";


</style>
