<template>
  <v-container class="pa-4 pa-md-0">
    <v-row no-gutters class="justify-space-between">
      <v-col class="d-flex">
        <v-btn
          :to="{name: 'Home'}"
          color="transparent"
          text
          rounded
          class="my-4 pa-0 pr-5"
          exact-active-class=""
        >
          <v-img width="130px" src="../assets/images/logo-01.svg"></v-img>
        </v-btn>
        <Search v-model="search" class="hidden-sm-and-down"/>

      </v-col>
      <v-col class="auth" cols="2">
        <v-menu v-if="authenticated && user" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-5 mt-5"
              color="transparent"
              elevation="0"

            >
              <v-icon large>
                mdi-account-outline
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :to="{name: 'UserProfile'}">
              <v-list-item-title>
                My Profile
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAdmin" :to="{name: 'Admin'}">
              <v-list-item-title>
                Dashboard
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else
               color="black"
               text rounded
               class="mt-5 mr-5 font-weight-bold subtitle-2 text-capitalize"
               @click="dialog(true)"
        >Sign In
        </v-btn>
        <cart-button/>
      </v-col>
    </v-row>
    <v-row class="d-flex d-md-none">
      <v-col cols="2" class="mt-6">
        <v-img @click="mobileMenu = !mobileMenu" width="25px" src="../assets/images/menu-01.svg"></v-img>
      </v-col>
      <v-col cols="10">
        <Search v-model="search" class="hidden-md-and-up"/>
      </v-col>
    </v-row>
    <v-row v-if="tags && tags.length > 0"
      class="px-2 pt-2 pb-6 hidden-sm-and-down"
      align-content="center"
      justify="space-between"
    >
      <router-link
        v-for="(item,idx) in tags"
        :key="item.id"
        :to="{name: 'books', query: { tag: item.id } }"
        class="menu-item text--black">
        {{ item.name ? item.name.replace(/\.$/, '') : '' }}
      </router-link>
      <!--      <router-link
              v-for="(item,idx) in links"
              :key="idx"
              :to="{name: item.to.name, params: { slug: item.to.id } }"
              class="menu-item text&#45;&#45;black">
              {{ item.title }}
            </router-link>-->
    </v-row>
    <v-row v-else><v-col cols="12">
      <pre>{{ tags }}</pre>
    </v-col></v-row>
    <v-row v-show="mobileMenu" class="hidden-md-and-up">
      <v-col>
        <ul class="mobile__menu">
          <li v-for="(item,idx) in tags" :key="item.id">
            <router-link
              :to="{name: 'books', params: { slug: item.id } }"
            > {{ item.name ? item.name.replace(/\.$/, '') : '' }}
            </router-link>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="crumbs" class="pa-0">
      <v-col cols="12" class="pa-0">
        <v-breadcrumbs class="pt-0 pl-2" divider=">" :items="crumbs"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog
          v-model="dialogSign"
          width="400"
        >
          <signin-signup-wrapper v-if="dialogSign"/>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';
import CartButton from "./CartButton";
import SigninSignupWrapper from "@/views/auth/SigninSignupWrapper";
import Search from "@/components/Search";
import ApiTags from "@/api/tags";

export default {
  name: "Header",
  components: {CartButton, Search, SigninSignupWrapper},
  data() {
    return {
      search: '',
      mobileMenu: false,
    }
  },
  async mounted() {
    if (this.tags === null || []) {
      this.fetchTags()
        .then(res => {
          // window.console.log('res tags', res);
        })
    }

  },
  computed: {
    ...mapState({
      'authenticated': state => state.auth.authenticated,
      'user': state => state.auth.user,
      'tags': state => state.tags.tags,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isDialogOpened: 'auth/isDialogOpened',
    }),
    dialogSign: {
      get() {
        return this.$store.state.auth.dialogSign;
      },
      set(val) {
        this.dialog(val);
      }
    },
    crumbs: function () {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: (this.$route.matched[idx] && this.$route.matched[idx].meta && this.$route.matched[idx].meta.breadCrumb) ? this.$route.matched[idx].meta.breadCrumb : path,
        });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      dialog: 'auth/dialog',
      fetchTags: 'tags/fetchTags'
    }),
  }
}
</script>

<style scoped lang="scss">

.nav {
  display: flex;
  justify-content: flex-start;
}

.mobile__menu {
  list-style: none;
  padding: 0;

  li {
    width: 100%;
    background: #efe5e6;
    border: 2px solid #fff;
    border-radius: 1em;
    padding: 1em 2em;

    a {
      color: #000;
      text-decoration: none;
    }
  }
}

.menu-item {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: .85em;
  color: #000000;
  text-decoration: none;

  &:hover {
    text-shadow: 0 0 3px #efe5e6;
    text-decoration: underline;
  }
}

.auth {
  display: flex;
  justify-content: flex-end;
}
</style>
