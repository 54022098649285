export const appInterface = {
  namespaced: true,
  state: () => ({
    preloader: false,
  }),
  mutations: {
    SHOW_PRELOADER: state => state.preloader = true,
    HIDE_PRELOADER: state => state.preloader = false,
    TOGGLE_PRELOADER: state => state.preloader = !state.preloader,
  }
}
