import ApiProductReviews from '@/api/product-reviews'

export const productReviews = {
  namespaced: true,

  state: {
    items: [],
  },

  getters: {

  },

  mutations: {
    SET_REVIEWS(state, reviews) {
      state.items = reviews
    },
  },
  actions: {
    async fetchReviews({commit}, params) {
      try {
        let response = await ApiProductReviews.index(params.product)
        commit('SET_REVIEWS', response.getData())
      } catch (e) {}
    }
  },
};
